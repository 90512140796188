.root {
  width: 200px;
  height: 50px;
  font-size: 18px;
  background-color: rgb(33, 55, 102);
  color: white;
  border: none;
  outline:0px;
  -webkit-appearance: none;
  appearance: none;
  padding: 0 20px;
}

.root option {
  padding: 10px;
}

.wrapper {
  position: relative;
  margin: auto;
  width: 200px;
  height: 50px;
  background-color: rgb(33, 55, 102);
}

.wrapper::after {
  color: white;
  content: "▼";
  font-size: 1rem;
  top: 14px;
  right: 12px;
  position: absolute;
}



@media (min-width: 500px) {
  .root {

  }
}
