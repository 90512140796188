.App {
  text-align: center;
  margin: 0.5em;
}

h1 {
  font-size: 1.5em;
  display: none;
}

@media (min-width: 500px) {
  h1 {
    display: block;
    font-size: 2em;
  }
}