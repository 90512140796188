.root {
  width: 90%;
  grid-template-columns: repeat( auto-fit, minmax(50px, 1fr) );
  gap: 10px 12px;
  margin: 20px auto;
  display: grid;
}

@media (min-width: 500px) and (max-width: 1024px) {
  .root {
    grid-template-columns: repeat( auto-fit, minmax(100px, 1fr) );
    grid-template-rows: repeat( auto-fit, 140px);
    gap: 15px 12px;
    min-height: 60vh;
    width: 90%;
    padding: 20px;
  }
}

@media (min-width: 1025px) {
  .root {
    grid-template-columns: repeat( auto-fit, minmax(100px, 1fr) );
    grid-template-rows: repeat( auto-fit, 140px);
    gap: 15px 12px;
    min-height: 60vh;
    width: 60%;
    padding: 20px;
  }
}