.root {
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 20px;
  padding: 10px;
}

@media (min-width: 500px) {
  .root {
    font-size: 34px;
    padding: 20px;
  }
}
