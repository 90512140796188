.root {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
}

.content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px 20px 40px 20px;
  border: 1px solid #888;
  border-radius: 6px;
  width: 80%;
}

.list {
  padding-inline-start: 0;
  margin: 0 2em;
  min-height: 200px;
}

.item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5em;
}

.button {
  background-color: rgb(33, 55, 102);
  outline: 0;
  color: white;
  border: 0;
  font-size: 1.1em;
  padding: 0.2em;
}

@media (min-width: 500px) {
  .content {
    width: 400px;
  }
}
